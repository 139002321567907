import React from 'react';
import { graphql } from 'gatsby';
import { Layout, SEO } from 'components';
import { Slices } from '../slices/slices';

const Location = (props) => {
  const { location, data } = props;
  const siteName = data && data.site && data.site.siteMetadata && data.site.siteMetadata.siteName;
  const dataPage = data && data.prismicService && data.prismicService.data;
  const dataSlices = dataPage && dataPage.body;
  const firstSlice = dataSlices && dataSlices[0] && dataSlices[0].slice_type;
  const isHero = firstSlice === 'hero_image' || firstSlice === 'hero_video';

  return (
    <Layout location={location} isHero={isHero}>
      <SEO
        title={`${dataPage && dataPage.title && dataPage.title.text} | ${siteName}`}
        desc={dataPage?.description?.text}
      />
      <Slices data={dataSlices} />
    </Layout>
  );
};

export default Location;

export const Query = graphql`
  query($uid: String!) {
    site {
      siteMetadata {
        siteName
      }
    }
    prismicService(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...ServiceAccordion
          ...ServiceContentDisplay
          ...ServiceFeatures
          ...ServiceForm
          ...ServiceHeroImage
          ...ServiceHeroVideo
          ...ServiceImageSlider
          ...ServiceImageText
          ...ServiceList
          ...ServiceProcess
          ...ServiceQuote
          ...ServiceStatistics
          ...ServiceText
          ...ServiceVideo
        }
      }
    }
  }
`;

//           ...ServiceFeaturesImages
